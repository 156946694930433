<template>
  <div style="max-width: 600px; margin: 100px auto 150px;">
    <div v-if="man && doneTwo">
      <div id="man">
        <div style="margin: 0 auto 6px;border-radius: 5px;width:160px;position:relative;" :class="man.quality+'-border'">
          <img src="/img/skull.png" class="skull" v-if="man.manpower < 1">
          <img :src="`https://images.godsunchained.com/art2/250/${man.pet.proto}.jpg`" class="pet" :class="man.pet.quality+'-border-thin'" v-if="man.pet">
          <img :src="`https://images.godsunchained.com/art2/250/${man.weapon.proto}.jpg`" class="weapon" :class="man.weapon.quality+'-border-thin'" v-if="man.weapon">
          <img src="/img/profile.jpg" class="manpf" :class="{'deadimg': man.manpower < 1}">
        </div>
        <h1>{{man.name}}</h1>
        <h2>(#{{man.id}})</h2>
        <p v-if="man.owner" style="display: block; width: 100%; text-align: center;">Owned by {{man.owner}}</p>
        <ul>
          <li><strong>Manpower:</strong> {{man.manpower}}</li>
          <li><strong>Quality:</strong> {{man.quality}}</li>
          <li><strong>Fights:</strong> {{man.fights}}</li>
          <li><strong>Wins:</strong> {{man.wins}} ({{ man.fights > 0 ? ((man.wins/man.fights)*100.0).toFixed(1) : "0.0" }}%)</li>
          <li><strong>Height:</strong> <span v-html="man.height"></span></li>
          <li><strong>Weight:</strong> <span v-html="man.weight"></span></li>
          <li><strong>Pet bonus: </strong>
            <span v-if="man.pet"> +{{man.pet.power}} from {{man.pet.name}} (#{{man.pet.id}})</span>
            <span v-else>None</span>
          </li>
          <li><strong>Weapon: </strong>
            <span v-if="man.weapon"> +{{man.weapon.power}} from {{man.weapon.name}} (#{{man.weapon.id}})</span>
            <span v-else>None</span></li>
          <li><strong>Injuries:</strong> {{man["injuries"].length === 0 ? "None" : man["injuries"].join(", ")}}</li>
        </ul>
        <h2>My Journal...</h2>
        <div v-for="entry in log" :key="entry['ref']" style="margin: 0 auto 12px">
          <em>{{entry.data.dt}}</em><br> {{entry.data.note}}
        </div>
      </div>
    </div>
    <div v-else-if="notReal">
      That's not a Real Man...
    </div>
    <div class="flash" v-else>
      Loading Real Man #{{$route.params.id}}...
    </div>
  </div>
</template>

<script>
import {ImmutableXClient} from "@imtbl/imx-sdk";
import faunadb from "faunadb";
const q = faunadb.query

export default {
  name: "PublicMan",
  props: ['db'],
  data() {
    return {
      man:null,
      log: null,
      doneTwo: false,
      notReal: false
    }
  },
  async mounted() {
    // TODO Error handling
    const self = this
    const client = await ImmutableXClient.build({publicApiUrl: 'https://api.x.immutable.com/v1'});
    let imxRes = await client.getAsset({
      address: '0xacb3c6a43d15b907e8433077b6d38ae40936fe2c',
      id: self.$route.params.id
    })
    self.man = imxRes.metadata
    if (imxRes.name === "A Real Man") {
      let faunaRes1 = await self.db.query(
          q.Get(
              q.Match(q.Index('man_by_id'), self.$route.params.id)
          )
      )
      self.man = {...self.man, ...faunaRes1.data}
      self.doneTwo = true

      let faunaRes2 = await self.db.query(
          q.Map(
            q.Paginate(
                q.Match(q.Index('log_by_man'), self.$route.params.id)
            ),
            q.Lambda(x => q.Get(x))
          )
      )
      self.log = faunaRes2.data

    } else {
      self.notReal = true
    }
  }
}
</script>

<style scoped>

</style>